@import "~reset-css/reset.css";
@import "~animate.css/animate.css";
@import "./bee3d/dist/css/bee3D.css";
@import "https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.3.3/semantic.min.css";
html {
  font-smoothing: antialiased;
}
.bee3D--parent {
  margin-top: 500px;
}
.bee3D--nav__prev,
.bee3D--nav__next {
  border: none;
  outline: none;
}
/* latin-ext */
@font-face {
  font-family: "Libre Baskerville";
  font-style: normal;
  font-weight: 400;
  src: local("Libre Baskerville"), local("LibreBaskerville-Regular"),
    url(https://fonts.gstatic.com/s/librebaskerville/v5/kmKnZrc3Hgbbcjq75U4uslyuy4kn0qNXaxMICA.woff2)
      format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Libre Baskerville";
  font-style: normal;
  font-weight: 400;
  src: local("Libre Baskerville"), local("LibreBaskerville-Regular"),
    url(https://fonts.gstatic.com/s/librebaskerville/v5/kmKnZrc3Hgbbcjq75U4uslyuy4kn0qNZaxM.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Libre Baskerville";
  font-style: normal;
  font-weight: 700;
  src: local("Libre Baskerville Bold"), local("LibreBaskerville-Bold"),
    url(https://fonts.gstatic.com/s/librebaskerville/v5/kmKiZrc3Hgbbcjq75U4uslyuy4kn0qviTgY5KcCsww.woff2)
      format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Libre Baskerville";
  font-style: normal;
  font-weight: 700;
  src: local("Libre Baskerville Bold"), local("LibreBaskerville-Bold"),
    url(https://fonts.gstatic.com/s/librebaskerville/v5/kmKiZrc3Hgbbcjq75U4uslyuy4kn0qviTgY3KcA.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: "Trade Winds";
  font-style: normal;
  font-weight: 400;
  src: local("Trade Winds"), local("TradeWinds"),
    url(https://fonts.gstatic.com/s/tradewinds/v6/AYCPpXPpYNIIT7h8-QenM0Jt5vM.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: "Crimson Text";
  font-style: italic;
  font-weight: 400;
  src: local("Crimson Text Italic"), local("CrimsonText-Italic"),
    url(https://fonts.gstatic.com/s/crimsontext/v8/wlpogwHKFkZgtmSR3NB0oRJfajhRK_Y.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: "Crimson Text";
  font-style: normal;
  font-weight: 400;
  src: local("Crimson Text Regular"), local("CrimsonText-Regular"),
    url(https://fonts.gstatic.com/s/crimsontext/v8/wlp2gwHKFkZgtmSR3NB0oRJfbwhT.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: "Crimson Text";
  font-style: normal;
  font-weight: 700;
  src: local("Crimson Text Bold"), local("CrimsonText-Bold"),
    url(https://fonts.gstatic.com/s/crimsontext/v8/wlppgwHKFkZgtmSR3NB0oRJX1C1GDNNQ.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
div {
  -webkit-user-select: none; /* Safari 3.1+ */
  -moz-user-select: none; /* Firefox 2+ */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Standard syntax */
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-left: 0;
  background: rgba(0, 0, 0, 0);
}
::-webkit-scrollbar-track {
  background: none;
}
::-webkit-scrollbar-thumb {
  background: rgb(246, 229, 163, 0.5);
  border-radius: 2px;
}
html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background-color: #000;
}

.bgBackgroundWrapper {
  height: 100vh;
  position: absolute;
  overflow: hidden;
  width: 100%;
}

.bgBackground {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  transform: scale(1.1);
}
.hidden {
  display: none;
}

.logo {
  transform-origin: center 0px;
  background: url("./images/logo.png");
  width: 50%;
  height: 100vh;
  top: 0;
  left: 50%;
  margin-left: -31%;
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  pointer-events: none;
  animation: swing2 1.5s ease-in-out infinite alternate-reverse;
}
/* .crowd {
  transform-origin: left 0px;
  background-image: url("./images/crowd.png");
  width: 50%;
  height: 100vh;
  top: 0;
  left: 45%;
  margin-left: -31%;
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  pointer-events: none;
  pointer-events: all;
  animation: swing1 1.5s ease-in-out infinite alternate-reverse;
} */
/* .crowd:hover {
  background-image: url("./images/crowd_over.png");
} */

.taalkeuze {
  width: 100%;
  height: auto;
  display: block;
}
.crowd {
  width: 100%;
  height: auto;
  display: block;
}
.hoverParent2 {
  position: absolute;
  width: 468px;
  height: 1372px;
  bottom: -940px;
  top: auto;
  left: 7.5%;
  zoom: 0.9;
  transform-origin: center 0px;
  animation: swing1 2s ease-in-out infinite alternate;
}
.hoverParent {
  position: absolute;
  width: 874px;
  height: 1007px;
  top: 0;
  right: 7.5%;
  transform-origin: center 0px;
  animation: swing1 2s ease-in-out infinite alternate;
}
.crowdOver {
  position: absolute;
  left: 45px;
  top: 657px;
  visibility: hidden;
  cursor: pointer;
}
.nlOver {
  position: absolute;
  left: 232px;
  top: 608px;
  visibility: hidden;
  cursor: pointer;
}
.frOver {
  position: absolute;
  left: 143px;
  top: 659px;
  visibility: hidden;
  cursor: pointer;
}
.enOver {
  position: absolute;
  left: 452px;
  top: 625px;
  visibility: hidden;
  cursor: pointer;
}
.loader {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
@keyframes swing1 {
  0% {
    transform: rotate(0.21deg);
  }
  100% {
    transform: rotate(-0.21deg);
  }
}
@keyframes swing2 {
  0% {
    transform: rotate(0.23deg);
  }
  100% {
    transform: rotate(-0.23deg);
  }
}
@keyframes swing3 {
  0% {
    transform: rotate(0.17deg);
  }
  100% {
    transform: rotate(-0.17deg);
  }
}
@keyframes swing4 {
  0% {
    transform: rotate(0.18deg);
  }
  100% {
    transform: rotate(-0.18deg);
  }
}
@keyframes swing5 {
  0% {
    transform: rotate(0.19deg);
  }
  100% {
    transform: rotate(-0.19deg);
  }
}

.hoverParentTourTickets {
  transform-origin: center 0px;
  width: 393px;
  height: 782px;
  top: 0;
  left: 32.81%;
  position: absolute;
  animation: swing1 2s ease-in-out infinite alternate;
}
.tourTickets {
  width: 100%;
  height: auto;
  display: block;
}
.tourTicketsOver {
  position: absolute;
  left: 15px;
  top: 598px;
  visibility: hidden;
  cursor: pointer;
}

.hoverParentGeschiedenis {
  transform-origin: center 0px;
  width: 381px;
  height: 687px;
  top: 0;
  left: 58.33%;
  position: absolute;
  animation: swing3 1.7s ease-in-out infinite alternate-reverse;
}
.geschiedenis {
  width: 100%;
  height: auto;
  display: block;
}
.geschiedenisOver {
  position: absolute;
  left: 13px;
  top: 527px;
  visibility: hidden;
  cursor: pointer;
}

.hoverParentVoorstellingen {
  position: absolute;
  width: 412px;
  height: 523px;
  top: 0;
  left: 22.5%;
  transform-origin: center 0px;
  animation: swing4 1.6s ease-in-out infinite alternate;
}
.voorstellingen {
  width: 100%;
  height: auto;
  display: block;
}
.voorstellingenOver {
  position: absolute;
  left: 19px;
  top: 356px;
  visibility: hidden;
  cursor: pointer;
}

.hoverParentContact {
  transform-origin: center 0px;
  width: 251px;
  height: 1002px;
  top: 0;
  left: 51.3%;
  position: absolute;
  animation: swing2 1.5s ease-in-out infinite alternate-reverse;
}
.contact {
  width: 100%;
  height: auto;
  display: block;
}
.contactOver {
  position: absolute;
  left: 3px;
  top: 824px;
  visibility: hidden;
  cursor: pointer;
}

.hoverParentMedia {
  transform-origin: center 0px;
  width: 269px;
  height: 603px;
  top: 0;
  left: 44.58%;
  position: absolute;
  animation: swing5 1.3s ease-in-out infinite alternate;
}
.media {
  width: 100%;
  height: auto;
  display: block;
}
.mediaOver {
  position: absolute;
  left: 20px;
  top: 451px;
  visibility: hidden;
  cursor: pointer;
}

.menu {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  transition: transform 300ms;
}
.menu:hover {
  transform: scale(1.1);
}
.back {
  position: absolute;
  left: 20px;
  top: 20px;
  cursor: pointer;
  transition: transform 300ms;
}
.back:hover {
  transform: scale(1.1);
}
.fullscreenIcon {
  position: absolute;
  right: 20px;
  bottom: 20px;
  cursor: pointer;
  transition: transform 300ms;
}
.fullscreenIcon:hover {
  transform: scale(1.1);
}
.menuIcon {
  position: absolute;
  left: 20px;
  bottom: 20px;
  width: 60px;
  height: 24px;
  display: block;
  cursor: pointer;
  transition: transform 300ms;
}
.mapHolder {
  height: 100%;
}
.menuIcon:hover {
  transform: scale(1.1);
}
.mapIcon {
  position: absolute;
  left: 20px;
  bottom: 60px;
  width: 61px;
  height: 61px;
  display: block;
  cursor: pointer;
  transition: transform 300ms;
}
.mapIcon:hover {
  transform: scale(1.1);
}
.preloaderHolder {
  opacity: 1;
  width: 200px;
  height: 200px;
  position: absolute;
  top: 200px;
  left: 50%;
  margin-left: -100px;
  animation-name: delayPreloader;
  animation-duration: 500ms;
  animation-timing-function: linear;
  animation-delay: 0;
  animation-iteration-count: 1;
  animation-direction: normal;
}

@keyframes delayPreloader {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

h1 {
  font-size: 80px;
  color: #000;
  font-family: Trade Winds;
  position: absolute;
  width: 550px;
  text-align: center;
  margin-left: -275px;
  left: 50%;
  top: 68px;
}

.events {
  display: flex;
  justify-content: space-between;
  padding: 0px 140px;
}
.events .animated span {
  display: block;
  transition: all 200ms ease-out;
}
.events .animated span.left:hover {
  transform: perspective(1000px) rotateY(10deg) translateY(-4px);
}
.events .animated span.right:hover {
  transform: perspective(1000px) rotateY(-10deg) translateY(-4px);
}
.event {
  display: block;
  cursor: pointer;
  transform-origin: center 0px;
  opacity: 0.9;
  transition: all 300ms;
  width: 100%;
  height: auto;
  will-change: transform, opacity;
}
.ui.popup {
  padding: 0;
  background-color: transparent;
  border-radius: 0;
  border: 0px solid #d4d4d5;
  background-image: url("./images/tooltip-bg.png");
  background-size: cover;
  box-shadow: 0 0px 0px 0 rgba(34, 36, 38, 0.12),
    0 0px 0px 0 rgba(34, 36, 38, 0.15);
}
.ui.header + p {
  width: 95%;
}
.ui.popup:before {
}
.ui.popup .header {
  color: white;
  margin-top: 5px;
  margin-bottom: 5px;
}
.ui.popup p {
  color: white;
  height: 100px;
  overflow-y: scroll;
  line-height: 16px;
}
.ui.popup p::-webkit-scrollbar-thumb {
  background: rgb(255, 255, 255, 0.8);
  border-radius: 2px;
}
.ui.popup p::-webkit-scrollbar {
  width: 2px;
}
.ui.floated.image,
.ui.floated.images {
  margin-bottom: 0;
  z-index: 2;
}
.ui.popup:before {
  background-color: white;
  display: none;
}
img.ui.image {
  padding: 9px 0px 18px 12px;
  border-radius: 10px;
}
.mapboxgl-marker {
  cursor: pointer;
}
.ui.top.center.popup {
}
.popupcontent {
  display: flex;
  width: 402px !important;
  height: 164px !important;
  flex-wrap: wrap;
}
.popupcontent > div > img {
}
.popupcontent > div:nth-child(1) {
  width: 50%;
}
.popupcontent > div:nth-child(2) {
  padding: 10px;
  width: 50%;
}
.event:hover {
  opacity: 1;
}
.event.event1 {
  animation: swing1 2s ease-in-out infinite alternate;
}
.event.event2 {
  animation: swing2 1.9s ease-in-out infinite alternate-reverse;
}
.event.event3 {
  animation: swing3 1.5s ease-in-out infinite alternate;
}
.event.event4 {
  animation: swing4 1.7s ease-in-out infinite alternate-reverse;
}
.anim {
  width: 100% !important;
}
.bee3D--slide {
  background-color: transparent;
}

.bee3D--effect__carousel,
.bee3D--effect__classic {
  background: transparent;
}
.bee3D--slide__active img {
  cursor: pointer;
  opacity: 0.9;
  transition: opacity 150ms;
}
.bee3D--slide__active img:hover {
  opacity: 1;
}

body {
  background: transparent;
  overflow: hidden;
}
.bee3D--slide img {
  display: block;
  width: 100%;
  height: auto;
  position: absolute;
  top: -500px;
}

.bee3D--slide:nth-child(0) img {
  transform-origin: center 0px;
  animation: swing1 1.6s ease-in-out infinite alternate;
}

.bee3D--slide:nth-child(1) img {
  transform-origin: center 0px;
  animation: swing2 1.6s ease-in-out infinite alternate-reverse;
}

.bee3D--slide:nth-child(2) img {
  transform-origin: center 0px;
  animation: swing3 1.6s ease-in-out infinite alternate;
}

.bee3D--slide:nth-child(3) img {
  transform-origin: center 0px;
  animation: swing4 1.6s ease-in-out infinite alternate-reverse;
}

.bee3D--slide:nth-child(4) img {
  transform-origin: center 0px;
  animation: swing5 1.6s ease-in-out infinite alternate;
}

.bee3D--slide:nth-child(5) img {
  transform-origin: center 0px;
  animation: swing1 1.6s ease-in-out infinite alternate-reverse;
}

.bee3D--slide:nth-child(6) img {
  transform-origin: center 0px;
  animation: swing2 1.6s ease-in-out infinite alternate;
}

.bee3D--slide:nth-child(7) img {
  transform-origin: center 0px;
  animation: swing3 1.6s ease-in-out infinite alternate-reverse;
}

.bee3D--slide:nth-child(8) img {
  transform-origin: center 0px;
  animation: swing4 1.6s ease-in-out infinite alternate;
}

.bee3D--slide:nth-child(9) img {
  transform-origin: center 0px;
  animation: swing5 1.6s ease-in-out infinite alternate-reverse;
}

.bee3D--slide:nth-child(10) img {
  transform-origin: center 0px;
  animation: swing1 1.6s ease-in-out infinite alternate;
}

.bee3D--slide:nth-child(11) img {
  transform-origin: center 0px;
  animation: swing2 1.6s ease-in-out infinite alternate-reverse;
}

.bee3D--slide:nth-child(12) img {
  transform-origin: center 0px;
  animation: swing3 1.6s ease-in-out infinite alternate;
}

.bee3D--slide:nth-child(13) img {
  transform-origin: center 0px;
  animation: swing4 1.6s ease-in-out infinite alternate-reverse;
}

.bee3D--slide:nth-child(14) img {
  transform-origin: center 0px;
  animation: swing5 1.6s ease-in-out infinite alternate;
}

.bee3D--slide:nth-child(15) img {
  transform-origin: center 0px;
  animation: swing1 1.6s ease-in-out infinite alternate-reverse;
}

.bee3D--nav {
  position: absolute;
  cursor: pointer;
  top: auto;
  font-size: inherit;
  width: 40px;
  height: 40px;
}

@keyframes swing1 {
  0% {
    transform: rotate(0.2deg);
  }
  100% {
    transform: rotate(-0.2deg);
  }
}

@keyframes swing2 {
  0% {
    transform: rotate(0.15deg);
  }
  100% {
    transform: rotate(-0.15deg);
  }
}

@keyframes swing3 {
  0% {
    transform: rotate(0.1deg);
  }
  100% {
    transform: rotate(-0.1deg);
  }
}

@keyframes swing4 {
  0% {
    transform: rotate(0.18deg);
  }
  100% {
    transform: rotate(-0.18deg);
  }
}

@keyframes swing5 {
  0% {
    transform: rotate(0.12deg);
  }
  100% {
    transform: rotate(-0.12deg);
  }
}

@media only screen and (max-width: 1200px) {
  .bee3D--parent {
    zoom: 0.9;
  }
}

@media only screen and (max-height: 900px) {
  .bee3D--parent {
    zoom: 0.85;
  }
}

@media only screen and (max-width: 1050px) {
  .bee3D--parent {
    zoom: 0.8;
  }
}

@media only screen and (max-height: 800px) {
  .bee3D--parent {
    zoom: 0.8;
  }
}

@media only screen and (max-width: 900px) {
  .bee3D--parent {
    zoom: 0.7;
  }
}

@media only screen and (max-height: 750px) {
  .bee3D--parent {
    zoom: 0.7;
  }
}

@media only screen and (max-height: 680px) {
  .bee3D--parent {
    zoom: 0.6;
  }
}

@media only screen and (max-height: 600px) {
  .bee3D--parent {
    zoom: 0.55;
  }
}
.pancarte {
  transform-origin: center 0;
  top: 10px;
  left: 50%;
  width: 86%;
  margin-left: -44%;
  position: absolute;
}
.pancarte2Holder {
  width: 100vw;
  height: 100vh;
  position: relative;
}
.pancarte2 {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
}
.pancarte2 .pancarteImage2 {
  display: block;
  width: 1600px;
  position: absolute;
  left: 50%;
  bottom: 80px;
  margin-left: -800px;
}
.pancarteImage2Image {
  display: block;
  width: 100%;
  height: auto;
}

@media screen and (max-width: 1750px) and (orientation: landscape) {
  .pancarte2 .pancarteImage2 {
    width: 90%;
    left: 5%;
    bottom: 4%;
    margin-left: 0px;
  }
}
@media screen and (max-width: 1550px) and (orientation: portrait) {
  .pancarte2 .pancarteImage2 {
    width: 100%;
    left: 0%;
    bottom: auto;
    margin-left: 0px;
  }
}
@media screen and (max-width: 1400px) and (orientation: landscape) {
  .pancarte2 .pancarteImage2 {
    width: 85%;
    left: 7.5%;
  }
}

.pancarte .pancarteImage {
  display: block;
  width: 100%;
  height: auto;
  margin-top: -18%;
}

.pancarteSmall {
  animation: swing2 1.5s ease-in-out infinite alternate-reverse;
  transform-origin: right 0px;
  top: -320px;
  right: 5%;
  position: absolute;
  width: 20%;
  margin-left: -45%;
  position: absolute;
}
.pancarteSmall ul {
  position: absolute;
  bottom: 7%;
  left: 16%;
  transform: rotate(3deg);
  width: 67%;
  height: 41%;
  line-height: 32px;
  font-size: 19px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.pancarteSmall ul li a {
  color: white;
  text-decoration: none;
  font-family: "Trade Winds";
  opacity: 0.6;
}
.pancarteSmall ul li a:hover,
.pancarteSmall ul li a.active {
  opacity: 0.9;
}
.pancarteSmall .pancarteImageSmall {
  display: block;
  width: 100%;
  height: auto;
}
.overlay {
  display: block;
  width: 90%;
  height: auto;
  position: absolute;
  left: 5.6%;
  bottom: 0;
}
.overlay2 {
  display: block;
  width: 100%;
  height: auto;
  position: absolute;
  left: 0%;
  bottom: 0;
}
.textWrapper {
  width: 40%;
  position: absolute;
  left: 7.3%;
  top: 39.5%;
  height: 45%;
  overflow-y: scroll;
  scroll-behavior: smooth;
  opacity: 0;
  transition: all 800ms ease-in;
  overflow-x: hidden;
}
.textWrapper.visible {
  opacity: 1;
}
.text {
  padding-right: 10px;
  position: absolute;
  top: -1000px;
  left: 0;
  color: #000;
  transition: all 800ms ease-in;
}
.textWrapper.visible .text {
  top: 0;
}
.text h1 {
  position: initial;
  left: auto;
  margin-left: 0;
  width: 100%;
  text-align: left;
  font-size: 30px;
  margin: 10px 0px;
  margin-top: 25px;
}
.text div.col-4:after {
  display: block;
  content: "";
  clear: both;
}
.text div.col-4 .polaroid {
  display: block;
  margin-right: 20px;
  width: calc(calc(100% - 60px) / 4);
  float: left;
  margin-bottom: 25px;
}

.text .polaroid {
  position: relative;
  width: 450px;
  margin-bottom: 25px;
}

.text .polaroid img {
  border: 10px solid #fffbeb;
  display: block;
  width: 100%;
  height: auto;
  border-bottom: 75px solid #fffbeb;
  -webkit-box-shadow: 3px 3px 3px #777;
  box-shadow: 3px 3px 3px #777;
}
.text > img {
  display: inline-block;
  margin: 15px;
  padding: 10px;
  background-color: #f9f5ec;
  border: 1px solid #000;
  height: auto;
  margin-bottom: 20px;
  max-width: 45%;
}
.textWrapper.visible .text p {
  text-align: left;
}
.textWrapper.visible .text {
  text-align: center;
}
blockquote {
  margin: 0 auto;
  max-width: 600px;
  position: relative;
  line-height: 32px;
  font-size: 23px;
  font-family: "Crimson Text", serif;
  margin-bottom: 60px;
  text-align: justify;
  margin-top: 60px;
}

blockquote::after,
blockquote::before {
  color: #000;
  font-size: 60px;
  line-height: 0.5;
}
blockquote::after {
  content: '"';
  vertical-align: bottom;
  position: absolute;
  right: -5%;
  bottom: -20px;
}
blockquote::before {
  content: '"';
  vertical-align: top;
  position: absolute;
  left: -5%;
}
.polaroid:nth-child(odd) {
  transform: rotate(0.5deg);
}
.polaroid:nth-child(even) {
  transform: rotate(-0.5deg);
}
.polaroid {
  transform: rotate(0.5deg);
}

.text .polaroid p {
  position: absolute;
  text-align: center;
  width: 90%;
  bottom: 0px;
  font: 400 18px/1 "Trade Winds";
  color: #000;
  line-height: 24px;
  margin: 0 auto;
  margin-bottom: 10px;
  left: 5%;
  height: 50px;
}

.text div.col-4 .polaroid:nth-child(4) {
  margin-right: 0px;
}

.text p {
  line-height: 32px;
  font-size: 23px;
  font-family: "Crimson Text", serif;
  margin-bottom: 20px;
}
.text em {
  font-style: italic;
}
.text li,
.text a {
  line-height: 32px;
  font-size: 23px;
  font-family: "Crimson Text", serif;
  color: inherit;
  text-align: left;
}
.text a,
.text strong {
  font-weight: 700;
  color: inherit;
  text-decoration: none;
}
.text a {
  text-decoration: underline;
}
.text a:hover {
  text-decoration: none;
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.markerIcon {
  color: rgb(85, 10, 14);
}
i.icon.marker:before {
  filter: drop-shadow(0px 0px 1px rgba(255, 255, 255, 1));
}
.ui.header.popupTitle {
  font-size: 20px;
  font-family: "Trade Winds";
}
.popupText {
  line-height: 17px;
  font-size: 13px;
  /* font-family: "Crimson Text", serif; */
  font-family: arial, helvetica, sans-serif;
}
.textWrapper h2 {
  text-align: center;
  font-size: 35px;
  color: #000;
  font-weight: bold;
  margin-bottom: 10px;
}
.table {
  width: 98%;
  border-collapse: separate;
  margin-bottom: 30px;
}
.table td {
  transition: all 300ms;
  opacity: 1;
  font-weight: 700;
  height: 16px;
  color: #000;
  font-size: 18px;
  font-family: "Libre Baskerville";
  padding: 7px 0px;
  border-style: solid;
  border-width: 2px 2px;
  -moz-border-image: url(./images/lijn.png) 1 0 0 1 repeat;
  -webkit-border-image: url(./images/lijn.png) 1 0 0 1 repeat;
  -o-border-image: url(./images/lijn.png) 1 0 0 1 repeat;
  border-image: url(./images/lijn.png) 1 0 0 1 repeat;
  padding: 8px 10px;
  border-bottom-width: 0px;
  overflow: hidden;
  line-height: 23px;
}
.table td:nth-child(1) {
  border-left-width: 0px;
  border-right-width: 0px;
  width: 30%;
}
.table td:nth-child(2) {
  border-right-width: 0px;
  width: 30%;
  min-width: 160px;
}
input[type="checkbox"] {
  display: none;
}
input[type="checkbox"] + label span {
  display: inline-block;
  width: 19px;
  height: 19px;
  margin: -5px 4px 0 0;
  vertical-align: middle;
  background: url(./images/check.png) left top no-repeat;
  cursor: pointer;
}
input[type="checkbox"]:checked + label span {
  background: url(./images/check.png) -19px top no-repeat;
}
.table td:nth-child(3) {
  text-transform: uppercase;
  border-right-width: 0px;
  width: 30%;
}
.table td:nth-child(4) {
  width: 10%;
  text-transform: uppercase;
  white-space: nowrap;
}
.table tr td {
  transition: all 400ms;
}
.table tr.hidden {
  display: block;
}
.table tr.hidden td {
  height: 0;
  padding-top: 0;
  padding-bottom: 0;
  line-height: 0;
  /* font-size: 0; */
  opacity: 0;
}
.table td a {
  color: #000;
  text-decoration: underline;
}
.table td a:hover {
  text-decoration: none;
}
.galleria {
  max-width: 70%;
  height: 700px;
  position: absolute;
  top: 10%;
  width: 100%;
  margin-left: 8.3%;
}
.galleria-theme-classic {
  background: transparent;
}
.galleria-theme-classic .galleria-loader {
  background: transparent;
}
.galleria-theme-classic .galleria-counter {
  color: #000;
}
.galleria-theme-classic .galleria-thumbnails .galleria-image {
  background: transparent;
}
.galleria-theme-classic .galleria-thumb-nav-left,
.galleria-theme-classic .galleria-thumb-nav-right,
.galleria-theme-classic .galleria-info-link,
.galleria-theme-classic .galleria-info-close,
.galleria-theme-classic .galleria-image-nav-left,
.galleria-theme-classic .galleria-image-nav-right {
  background-image: none;
}
.checkboxes {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
  position: absolute;
  left: 8%;
  top: 32.5%;
  height: 32px;
  width: 85%;
  border-style: solid;
  border-width: 0px;
}

.checkboxes label {
  white-space: nowrap;
  text-transform: uppercase;
  font-size: 20px;
  color: #000;
  font-family: "Libre Baskerville";
  font-weight: 700;
  cursor: pointer;
}
.loader {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
}
.contact h1 {
  position: initial;
  width: 100%;
  text-align: center;
  margin-left: 0px;
  left: auto;
  top: auto;
  font-size: 32px;
}
.contact h1:nth-of-type(2) {
  margin-top: 60px;
}
.contact p {
  line-height: 32px;
  font-size: 23px;
  text-align: center;
  font-family: "Crimson Text", serif;
  margin-bottom: 20px;
}
.contact p:last-child {
  margin-bottom: 0px;
}
.contact p a {
  color: #000;
  display: inline-block;
  margin: 0px 15px;
}
.contact p a:hover {
  text-decoration: none;
}

.contact p:last-child a {
  font-size: 25px;
  font-weight: bold;
}

@media screen and (max-width: 1750px) {
  .events {
    padding: 0px 70px;
  }
  .hoverParent2 {
    zoom: 0.9;
  }
}
@media screen and (max-width: 1650px) {
  .hoverParent2 {
    zoom: 0.8;
  }
  .events {
    padding: 0px 50px;
  }
  .hoverParentTourTickets {
    left: 25%;
  }
  .hoverParentContact {
    left: 51.3%;
  }
  .hoverParentGeschiedenis {
    left: 65%;
  }
  .hoverParentVoorstellingen {
    left: 15%;
  }
  .hoverParentMedia {
    left: 44.58%;
  }
  .checkboxes label {
    font-size: 17px;
  }
}
@media screen and (max-width: 1400px) {
  .events {
    padding: 0px 35px;
  }
  .hoverParent2 {
    zoom: 0.75;
    bottom: -1000px;
  }
}
@media screen and (max-width: 1330px) {
  .pancarte {
    left: 50%;
    position: absolute;
    width: 98%;
    margin-left: -49%;
    position: absolute;
  }
  .hoverParent2 {
    zoom: 0.7;
    bottom: -1000px;
    left: 2%;
  }
}
@media screen and (max-width: 1150px) {
  .pancarte {
    left: 50%;
    width: 100%;
    margin-left: -50%;
    position: absolute;
    top: 85px;
  }
  .hoverParent2 {
    zoom: 0.6;
    left: 0.5%;
  }
}
@media screen and (max-width: 1300px) {
  .events {
    padding: 0px 20px;
  }
  .hoverParentTourTickets {
    left: 16%;
  }
  .hoverParentContact {
    left: 51.3%;
  }
  .hoverParentGeschiedenis {
    left: 65%;
  }
  .hoverParentVoorstellingen {
    left: 8%;
  }
  .hoverParentMedia {
    left: 44.58%;
  }
}

@media screen and (max-width: 1420px) {
  .checkboxes {
    flex-wrap: wrap;
    height: 64px;
    top: 33%;
    border-bottom-width: 0px;
    padding-left: 0%;
    padding-right: 3%;
    width: 88%;
  }
  .checkboxes.media {
    top: 10%;
  }
  .checkboxes > div {
    width: 50%;
    height: 30px;
  }
  .checkboxes > div:nth-child(2),
  .checkboxes > div:nth-child(4) {
    width: 50%;
    text-align: right;
  }
  .tour .textWrapper {
    height: 52% !important;
  }
}

@media screen and (max-width: 1650px) {
  .events {
    padding: 0px 15px;
  }
  .hoverParent {
    right: 1%;
  }
  .logo {
    width: 62%;
    height: 100vh;
    margin-left: -31%;
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    pointer-events: none;
  }
}
@media screen and (max-width: 1150px) {
  .hoverParentTourTickets {
    left: 9%;
  }
  .hoverParentContact {
    left: 55.3%;
  }
  .hoverParentGeschiedenis {
    left: 65%;
  }
  .hoverParentVoorstellingen {
    left: 3%;
  }
  .hoverParentMedia {
    left: 44.58%;
  }
}
@media screen and (max-width: 1050px) {
  .hoverParentTourTickets {
    left: 9%;
  }
  .hoverParentContact {
    left: 55.3%;
  }
  .hoverParentGeschiedenis {
    left: auto;
    right: 0;
  }
  .hoverParentVoorstellingen {
    left: 1%;
  }
  .hoverParentMedia {
    left: 38%;
  }
}
@media screen and (max-width: 990px) {
  .events {
    flex-wrap: wrap;
    padding: 0 75px;
  }
  .events > .animated {
    width: 46%;
  }
  .events > .animated:nth-child(1) {
    z-index: 50;
  }
  .events > .animated:nth-child(2) {
    z-index: 50;
  }
  .events > .animated:nth-child(3) {
    z-index: 10;
  }
  .events > .animated:nth-child(4) {
    z-index: 10;
  }
  .events .animated span.left:hover {
    transform: translateY(-4px);
  }
  .events .animated span.right:hover {
    transform: translateY(-4px);
  }
  .event.event1 {
    top: -130px;
    position: absolute;
  }
  .event.event2 {
    top: -130px;
    position: absolute;
  }
  .event.event3 {
    top: +295px;
    position: absolute;
  }
  .event.event4 {
    top: +295px;
    position: absolute;
  }

  .hoverParentTourTickets {
    left: 9%;
  }
  .hoverParentContact {
    left: 60.3%;
  }
  .hoverParentGeschiedenis {
    top: -75px;
  }
  .hoverParentVoorstellingen {
    top: -200px;
  }
  .hoverParentMedia {
    top: -140px;
  }
}
@media screen and (max-height: 980px) {
  .hoverParent {
    top: -60px;
  }
  .hoverParentTourTickets {
    top: -50px;
  }
  .hoverParentContact {
    top: -85px;
    left: 61%;
  }
  .hoverParentGeschiedenis {
    top: -125px;
  }
  .hoverParentVoorstellingen {
    top: -315px;
  }
  .hoverParentMedia {
    top: -200px;
  }
}
@media screen and (max-width: 900px) {
  .hoverParent {
    right: -4%;
  }
}

@media screen and (max-height: 925px) {
  .hoverParent {
    top: -150px;
  }
  .hoverParentContact {
    top: -225px;
  }
  .hoverParentGeschiedenis {
    top: -180px;
  }
  .hoverParentVoorstellingen {
    top: -325px;
  }
  .hoverParentMedia {
    top: -240px;
  }
}
@media screen and (max-height: 840px) {
  .logo {
    top: -50px;
  }
  .hoverParent {
    top: -200px;
  }
  .hoverParentContact {
    top: -325px;
  }
  .hoverParentGeschiedenis {
    top: -220px;
  }
  .hoverParentVoorstellingen {
    top: -325px;
  }
  .hoverParentMedia {
    top: -284px;
  }
  .hoverParentTourTickets {
    top: -150px;
  }
}
@media screen and (max-height: 775px) {
  .logo {
    top: -90px;
  }
  .hoverParent {
    top: -250px;
  }
  .hoverParentContact {
    top: -325px;
  }
  .hoverParentGeschiedenis {
    top: -220px;
  }
  .hoverParentVoorstellingen {
    top: -325px;
  }
  .hoverParentMedia {
    top: -284px;
  }
  .hoverParentTourTickets {
    top: -150px;
  }
}

@media screen and (max-height: 720px) {
  .logo {
    top: -100px;
  }
  .hoverParent {
    top: -300px;
  }
  .hoverParentContact {
    top: -325px;
  }
  .hoverParentGeschiedenis {
    top: -220px;
  }
  .hoverParentVoorstellingen {
    top: -325px;
  }
  .hoverParentMedia {
    top: -284px;
  }
  .hoverParentTourTickets {
    top: -150px;
  }
}
@media screen and (max-height: 680px) {
  .hoverParent {
    zoom: 0.8;
    top: -200px;
  }
}
@media screen and (max-height: 625px) {
  .hoverParent {
    zoom: 0.7;
    top: -140px;
  }
}
@media screen and (max-height: 590px) {
  .hoverParent {
    top: -170px;
  }
  .logo {
    top: -80px;
  }
}

@media screen and (max-width: 1150px) and (orientation: landscape) {
  .pancarte2 .pancarteImage2 {
    width: 100%;
    left: 0%;
    bottom: auto;
    top: -12%;
    margin-left: 0;
  }
  .table td {
    font-size: 15px;
    padding: 0px 3px;
  }
}
.turn {
  display: none;
  width: 100vw;
  height: 100vh;
  /* background-color: #000; */
}
@media screen and (max-width: 800px) and (orientation: portrait) {
  body > *:not(.turn) {
    display: none;
  }
  .turn {
    display: block;
    color: #fff;
    font-size: 35px;
    padding: 20px;
    line-height: 45px;
    text-align: center;
    font-family: arial, helvetica, sans-serif;
  }
}
@media screen and (max-width: 1500px) and (orientation: landscape) {
  .pancarteSmall {
    top: -170px;
  }
  .pancarteSmall ul::-webkit-scrollbar {
    width: 2px;
    height: 5px;
    border-left: 0;
    background: rgba(0, 0, 0, 0);
  }
  .pancarteSmall ul::-webkit-scrollbar-track {
    background: none;
  }
  .pancarteSmall ul::-webkit-scrollbar-thumb {
    background: rgb(255, 255, 255, 0.5);
    border-radius: 2px;
  }
}
/*
@media screen and (max-height: 870px) and (max-width: 1200px) {
  .logo {
    top: -50px;
  }
}
@media screen and (max-height: 820px) and (max-width: 1050px) {
  .logo {
    top: -100px;
  }
  .hoverParent {
    top: -200px;
  }
}
@media screen and (max-height: 850px) and (max-width: 1000px) {
  .hoverParent {
    top: -225px;
  }
}
@media screen and (max-height: 750px) and (max-width: 900px) {
  .hoverParent {
    top: -260px;
  }
}
@media screen and (max-width: 800px) {
  .hoverParent {
    right: -9%;
    animation: none;
    transform: scale(0.9);
  }
} */
